<template>
  <!-- 面包屑 -->
  <div class="user_information">
    <crumbs class="breadcrumb-container" />

    <keep-alive>
      <router-view v-if="$route.meta.keepAlive == true" />
    </keep-alive>

    <router-view v-if="$route.meta.keepAlive == false" />
  </div>
</template>
<script>
import Crumbs from "../../../components/crumbs"; //面包屑
export default {
  components: {
    Crumbs
  }
};
</script>

<style lang="less">
.user_information {
  height: 100%;
}
.breadcrumb-container {
  width: 100%;
  border-bottom: 1px solid rgb(217, 242, 247);
}
</style>